
<template>
  <div class="page">
    <!-- <top-bar :title='this.$route.query.title' :left="true"></top-bar> -->
    <div class="list">
      <div
          class="item"
          v-for="item in recentWorkDate"
          :key="item.id"
          @click="navToFun(item.id, item.categoryName, item.category)"
      >
        <div class="top">
          <span class="time">{{ item.createTime }}</span>
          <span class="status text-red" v-if="item.finishStatus == 1"
          >未完成</span
          >
          <span class="status text-blue" v-else>已完成</span>
        </div>
        <div class="bottom">
          <div class="content">{{ item.title }}</div>
          <div class="name text-blue">认领人:{{ item.getUser || "未知" }}</div>
        </div>
        <div class="arrow">
          <van-icon name="arrow" size="14" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
export default {
  components: {
    topBar,
  },
  data() {
    return {
      category: "0",
      recentWorkDate: [],
      loading: true,
    };
  },
  methods: {
    getDataList() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      let category = this.$route.query.category;
      this.$httpApp({
        url: this.$httpApp.adornUrl("/wxapp/app/appjqworktarget/list"),
        method: "post",
        params: this.$httpApp.adornParams({
          category: category,
          page: 0,
          limit: 1000,
        }),
      }).then(
          ({ data }) => {
            if (data.code == 0) {
              this.recentWorkDate = data.page.list;
              // 加载状态结束
              this.loading = false;
              this.$toast.clear();
            }
            this.$toast.clear();
          },
          (err) => {
            this.$toast.clear();
          }
      );
    },
    navToFun(userId, title, category) {
      this.$router.push({
        name: "recentWorkInfo",
        query: { userId, title, category },
      });
    },
  },
  mounted() {
    this.category = this.$route.query.category;
    this.getDataList();
  },
};
</script>

<style scoped lang="scss" >
.page {
  width: 100%;
  height: 100vh;
  position: relative;
  padding: 0 0 100px 0;
  box-sizing: border-box;
  background: #f5f5f5;
  font-size: 24px;
  .list {
    padding: 30px;
    .item {
      position: relative;
      width: 100%;
      background: #fff;
      padding: 30px;
      box-sizing: border-box;
      border-radius: 10px;
      margin-bottom: 20px;
      .top {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;
      }
      .bottom {
        padding: 30px 30px 0 0;
        .content {
          color: #000;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 32px;
        }
        .name {
          margin-top: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 30px;
          line-height: 30px;
        }
      }
      .arrow {
        position: absolute;
        right: 30px;
        top: 50%;
      }
    }
  }
}
</style>
